import { Typography } from '@mui/material';

const HowMuchMortgageCanIAfford = () => (
    <>
        <Typography component="p" fontWeight={400} fontSize={16} lineHeight="21.79px">
            Mortgage calculators are an excellent way of finding out how much you might be able to borrow. However, calculators can only make an estimate: they do not take everything into account. As such, it's crucial you understand what factors the online mortgage calculator you are using considers.
        </Typography>
        <Typography marginTop="20px" fontWeight={400} fontSize={16} lineHeight="21.79px">
            Each mortgage calculator is different, but basic online mortgage calculators will usually look at:
        </Typography>
        <ul>
            <li>
                <Typography fontWeight={400} fontSize={16} lineHeight="21.79px">
                    how many people are paying the mortgage
                </Typography>
            </li>
            <li>
                <Typography fontWeight={400} fontSize={16} lineHeight="21.79px">
                    salaries
                </Typography>
            </li>
            <li>
                <Typography fontWeight={400} fontSize={16} lineHeight="21.79px">
                    secondary income
                </Typography>
            </li>
            <li>
                <Typography fontWeight={400} fontSize={16} lineHeight="21.79px">
                    mortgage type
                </Typography>
            </li>
            <li>
                <Typography fontWeight={400} fontSize={16} lineHeight="21.79px">
                    mortgage length
                </Typography>
            </li>
            <li>
                <Typography fontWeight={400} fontSize={16} lineHeight="21.79px">
                    interest rate
                </Typography>
            </li>
        </ul>
        <Typography marginTop="20px" fontWeight={400} fontSize={16} lineHeight="21.79px">
            Most mortgage calculators do not look at:
        </Typography>
        <ul>
            <li>
                <Typography fontWeight={400} fontSize={16} lineHeight="21.79px">
                    monthly expenses
                </Typography>
            </li>
            <li>
                <Typography fontWeight={400} fontSize={16} lineHeight="21.79px">
                    credit score
                </Typography>
            </li>
            <li>
                <Typography fontWeight={400} fontSize={16} lineHeight="21.79px">
                    costs of getting a mortgage
                </Typography>
            </li>
            <li>
                <Typography fontWeight={400} fontSize={16} lineHeight="21.79px">
                    interest rate changes
                </Typography>
            </li>
            <li>
                <Typography fontWeight={400} fontSize={16} lineHeight="21.79px">
                    life changes such as losing your job
                </Typography>
            </li>
        </ul>
        <Typography marginTop="20px" fontWeight={400} fontSize={16} lineHeight="21.79px">
            Unlike calculators, most lenders look at every issue that could affect your repayments. You might also need to pass a lender’s ‘stress test’ before they’ll give you a mortgage. This is to make sure you’ll be able to pay your mortgage if something happens that affects your repayments.
        </Typography>
        <Typography marginTop="20px" fontWeight={400} fontSize={16} lineHeight="21.79px">
            This could include:
        </Typography>
        <ul>
            <li>
                <Typography fontWeight={400} fontSize={16} lineHeight="21.79px">
                    losing your job
                </Typography>
            </li>
            <li>
                <Typography fontWeight={400} fontSize={16} lineHeight="21.79px">
                    having a baby
                </Typography>
            </li>
            <li>
                <Typography fontWeight={400} fontSize={16} lineHeight="21.79px">
                    being ill
                </Typography>
            </li>
            <li>
                <Typography fontWeight={400} fontSize={16} lineHeight="21.79px">
                    a change in interest rates
                </Typography>
            </li>
        </ul>
        <Typography marginTop="20px" fontWeight={400} fontSize={16} lineHeight="21.79px">
            To pass the stress test, lenders will look at your salary and other types of income such as pensions and investments. Lenders also look at your credit history to see what type of borrower you are. This is called a credit check. It could be a hard or soft credit check, depending on their rules.
        </Typography>
    </>
);

export default HowMuchMortgageCanIAfford;