import { Typography } from '@mui/material';

const HowMuchCanIBorrowInfo = () => (
    <>
        <Typography>
            You can usually borrow around 4 to 5 times your salary.
        </Typography>
        <Typography marginTop="20px">
            Some lenders offer up to 6 times your salary, but they will be very strict about who they lend this amount to. Lenders also have different rules and the income multiple they allow can depend on many things.
        </Typography>
        <Typography marginTop="20px">
            They include:
        </Typography>
        <ul>
            <li>
                <Typography>
                    salary and source of income
                </Typography>
            </li>
            <li>
                <Typography>
                    using a government homeownership scheme
                </Typography>
            </li>
            <li>
                <Typography>
                    extra benefits (for example, Barclays offer Premier customers slightly higher income multiples)
                </Typography>
            </li>
            <li>
                <Typography>
                    deposit size
                </Typography>
            </li>
            <li>
                <Typography>
                    financial commitments and bills
                </Typography>
            </li>
            <li>
                <Typography>
                    age
                </Typography>
            </li>
            <li>
                <Typography>
                    length of mortgage
                </Typography>
            </li>
            <li>
                <Typography>
                    leasehold costs
                </Typography>
            </li>
        </ul>
    </>
);

export default HowMuchCanIBorrowInfo;