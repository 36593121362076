import dot from 'dot-object'

export const isEmpty = (fieldName: string, values: any) =>
    !dot.pick(fieldName, values)

export const validateField = (
    fieldName: string,
    errorMessage: string,
    customValidator: boolean = false
) => {
    const errors: Record<any, string> = {}

    if (customValidator) {
        errors[fieldName] = errorMessage
    }

    return errors
}
