// import { isEmpty, validateField } from "../validator";

export const validateInterestOnly = (values: Record<any, any>) => {
    return {
        // ...validateField(
        //     'annual_salary',
        //     'This field is required',
        //     isEmpty('annual_salary', values)
        // ),
        // ...validateField(
        //     'monthly_outgoings',
        //     'This field is required',
        //     isEmpty('monthly_outgoings', values)
        // ),
        // ...validateField(
        //     'deposit_amount',
        //     'This field is required',
        //     isEmpty('deposit_amount', values)
        // ),
    };
};

export const validateCI = (values: Record<any, any>) => {
    return {};
};