import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { AppConfig } from "../../App";
import { Accordion, Banner, Calculator, Footer, Header } from "../../components";
import HowMuchCanIBorrowInfo from "../../components/accordion/how-much-can-i-borrow";
import HowMuchCanPeopleBorrowWithBadCredit from "../../components/accordion/how-much-can-i-borrow-bad-credit";
import HowMuchCanIBorrowWithNoDeposit from "../../components/accordion/how-much-can-i-borrow-no-deposit";
import HowMuchMortgageCanIAfford from "../../components/accordion/how-much-mortgage-can-i-afford";
import HowMuchPeopleBorrow from "../../components/accordion/how-much-people-borrow";
import ShouldIBorrowMaximumAmount from "../../components/accordion/should-i-borrow-maximum-amount";
import { validateCI, validateInterestOnly } from "./validator";

import "../index.scss";

const HowMuchCanIBorrow = ({config}: { config: AppConfig }) => {
    const isCalculatorOnly = config?.view === 'calculator';
    const header = config?.header;
    const footer = config?.footer;
    const faq = config?.faq;

    const [payment, setPayment] = useState({
        interestOnly: {
            total: 0,
            ltv: 0,
            borrowingAmount: 0,
        },
        ci: {
            total: 0,
            ltv: 0,
            borrowingAmount: 0,
        },
        isInitialValues: true,
    })

    const formFields = [{
        formType: "input",
        inputConfig: {
            label: "What is your annual salary?",
            name: "annual_salary",
            type: "numeric",
            info: "Include regular salary (before tax), working tax credit, car allowance, and any other cash benefits.",
            startAdornment: "£",
            ...(config?.annualSalary && {
                value: config?.annualSalary!,
            }),
        },
    }, {
        formType: "input",
        inputConfig: {
            label: "Partner's salary (if applicable)",
            name: "partner_annual_salary",
            type: "numeric",
            info: "Include regular salary (before tax), working tax credit, car allowance, and any other cash benefits.",
            startAdornment: "£",
            ...(config?.partnerAnnualSalary && {
                value: config?.partnerAnnualSalary!,
            }),
        },
    }, {
        formType: "input",
        inputConfig: {
            label: "Deposit Amount",
            name: "deposit_amount",
            type: "numeric",
            info: "You’ll need to provide at least a 5% deposit to get a mortgage. For example, you’d need at minimum £10, 000 deposit to buy a £200, 000 home.",
            startAdornment: "£",
            ...(config?.depositAmount && {
                value: config?.depositAmount!,
            }),
        },
    }, {
        formType: "input",
        inputConfig: {
            label: "Regular monthly outgoings (optional)",
            name: "monthly_outgoings",
            type: "numeric",
            info: "Lenders take regular monthly spending into account when deciding how much to lend. This includes loan repayments, credit cards, childcare fees, pension payments, etc.",
            startAdornment: "£",
            ...(config?.monthlyOutgoings && {
                value: config?.monthlyOutgoings!,
            }),
        },
    }] as any;

    const handleSubmit = (values: Record<any, any>, activeTab: number) => {
        const app1Salary = parseFloat(values?.annual_salary || 0);
        const app2Salary = parseFloat(values?.partner_annual_salary || 0);
        const depositAmount = parseFloat(values?.deposit_amount || 0)
        const monthlyOutgoings = parseFloat(values?.monthly_outgoings || 0);
        const annualSalary = app1Salary + app2Salary;
        let lendingAmount = 0;

        if (monthlyOutgoings > (annualSalary / 24)) {
            lendingAmount = 4 * annualSalary;
        } else {
            lendingAmount = 4.75 * annualSalary;
        }

        const borrowingAmount = lendingAmount + depositAmount;
        const ltv = Math.round((lendingAmount / borrowingAmount) * 100);

        if (activeTab === 0) {
            setPayment({
                ...payment,
                interestOnly: {
                    ...payment?.interestOnly,
                    total: lendingAmount,
                    borrowingAmount,
                    ltv,
                },
                isInitialValues: false,
            })
        } else if (activeTab === 1) {
            setPayment({
                ...payment,
                ci: {
                    ...payment?.ci,
                    total: lendingAmount,
                    borrowingAmount,
                    ltv,
                },
                isInitialValues: false,
            })
        }
    };

    const renderAccordion = () => {
        return (
            <Box paddingTop="79px" paddingBottom="91px">
                <Typography className="your-question-answered">
                    Your question answered!
                </Typography>
                <Box marginTop="33px">
                    <Accordion
                        title="How much can I borrow for my mortgage?"
                        content={<HowMuchCanIBorrowInfo />}
                    />
                    <Accordion
                        title="How much mortgage can I afford?"
                        content={<HowMuchMortgageCanIAfford />}
                    />
                    <Accordion
                        title="Should I borrow the maximum amount?"
                        content={<ShouldIBorrowMaximumAmount />}
                    />
                    <Accordion
                        title="How much do most people borrow?"
                        content={<HowMuchPeopleBorrow />}
                    />
                    <Accordion
                        title="How much can I borrow with bad credit?"
                        content={<HowMuchCanPeopleBorrowWithBadCredit />}
                    />
                    <Accordion
                        title="How much can I borrow with no deposit?"
                        content={<HowMuchCanIBorrowWithNoDeposit />}
                    />
                </Box>
            </Box>
        )
    }

    const renderCalculator = () => {
        return (
            <Calculator
                config={config}
                height="600px"
                title="Mortgage Calculator"
                description="Try our Calculator to find out how much you can borrow in the UK. You can see the difference between an Interest Only and Repayment Mortgage."
                headings={[{
                    heading: "You could borrow up to",
                    total: payment?.interestOnly?.total,
                    subTexts: [
                        `Loan to value (LTV): <span class='value'>${payment?.interestOnly?.ltv}%</span>`,
                        `Including your deposit, you could afford a house price up to £ <span class='value'>${payment?.interestOnly?.borrowingAmount.toLocaleString()}</span>`
                    ],
                    isInitialValues: payment?.isInitialValues
                }, {
                    heading: "You could borrow up to",
                    total: payment?.ci?.total,
                    subTexts: [
                        `Loan to value (LTV): <span class='value'>${payment?.ci?.ltv}%</span>`,
                        `Including your deposit, you could afford a house price up to £ <span class='value'>${payment?.ci?.borrowingAmount.toLocaleString()}</span>`
                    ],
                    isInitialValues: payment?.isInitialValues
                }]}
                tabs={["Interest Only Calculator", "Capital & Interest Calculator"]}
                forms={[
                    formFields,
                    formFields,
                ]}
                disclaimers={[
                    "You may have to pay an early repayment charge if you remortgage.",
                    "Your home could be repossessed if you don't keep up repayments on your mortgage. This calculator is only an estimate of how much you may be able to borrow.",
                ]}
                validations={[
                    validateInterestOnly,
                    validateCI,
                ]}
                handleSubmit={handleSubmit}
            />
        )
    }

    return (
        <>
            {(!isCalculatorOnly && !header) && <Header />}
            {(!isCalculatorOnly && !header) && <Banner
                bannerTitle="How much can I borrow?"
                bannerSubtitle="Discover how much you could borrow for your mortgage using our calculator"

                align="left"
                bannerHelpText="Your home could be repossessed if you don't keep up repayments on your mortgage."
            />}
            <Box maxWidth="1014px" margin="auto" padding={(!isCalculatorOnly && !header) ? "20px" : "20px"}>
                {renderCalculator()}
                {(!isCalculatorOnly && !faq) && renderAccordion()}
            </Box>
            {(!isCalculatorOnly && !footer) && <Footer />}
        </>
    )
};

export default HowMuchCanIBorrow;