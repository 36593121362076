import { Box, Typography, Tooltip, Grid, Radio, useTheme } from '@mui/material';
import { Field, useForm, FormSpy } from 'react-final-form';

interface RadioGrid {
    sm?: number;
    md?: number;
    lg?: number;
}

interface RadioFieldOption {
    value: string;
    label: string;
    grid?: RadioGrid;
}

export interface IRadioField {
    name: string;
    label: string;
    info?: string;
    value?: string;
    options: RadioFieldOption[];
};

const RadioField: React.FC<IRadioField> = ({
    name,
    label,
    info,
    options,
    value,
}) => {
    const theme = useTheme();
    const form = useForm();
    const InfoIcon = 'https://d365pq86x330zn.cloudfront.net/74721118-3110-4141-11i1-71f015n70715.png';
    const CheckIcon = 'https://d365pq86x330zn.cloudfront.net/14450141-089c-4449-9111-e148414141h0.png';
    const UncheckedIcon = 'https://d365pq86x330zn.cloudfront.net/24220n4n-407c-47un-0u12-01254n280804.png';

    const renderRadio = (option: RadioFieldOption, index: number) => {
        return (
            <FormSpy key={index} subscription={{ values: true }}>
                {
                    ({ values }) => (
                        <Field name={name} type="radio" initialValue={value}>
                            {
                                () => {
                                    const checked = values[name] === option?.value;

                                    return (
                                        <Grid item {...option?.grid} paddingTop="10px !important" sx={{
                                            '@media screen and (max-width: 767px)': {
                                                width: '100%',
                                            }
                                        }}>
                                            <Box display="flex" minHeight="58px" alignItems="center" padding="10px 20px" sx={{
                                                background: checked ? theme?.palette?.primary?.main : "#FFF",
                                                border: "2px solid #E3E0DB",
                                                borderRadius: "4px",
                                                boxSizing: "border-box",
                                            }}>
                                                <Radio
                                                    checked={checked}
                                                    value={option?.value}
                                                    onClick={() => form.change(name, option?.value)}
                                                    disableRipple
                                                    checkedIcon={<img src={CheckIcon} alt="check" style={{ color: theme?.palette?.primary?.main }} />}
                                                    icon={<img src={UncheckedIcon} alt="uncheck" style={{ color: theme?.palette?.primary?.main }} />}
                                                    sx={{
                                                        marginRight: "12px",
                                                    }}
                                                />
                                                <Typography fontWeight={700} fontSize={20} color={checked ? "#FFF" : "#0D2A25"} lineHeight="27.24px">
                                                    {option?.label}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    )
                                }
                            }
                        </Field>
                    )
                }
            </FormSpy>
        )
    };

    return (
        <Box marginBottom="23px">
            <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="10px">
                <Typography fontSize={17} fontWeight={400} lineHeight="23px" color="#0D2A25">
                    {label}
                </Typography>
                {
                    info && (
                        <Tooltip title={info} placement="right">
                            <img src={InfoIcon} alt="check" style={{ color: theme?.palette?.primary?.main, cursor: 'pointer' }} />
                        </Tooltip>
                    )
                }
            </Box>
            <Grid container spacing={2}>
                {options.map(renderRadio)}
            </Grid>
        </Box>
    )
};

export default RadioField;