import { isEmpty, isOutOfRange, validateField } from "../validator";

export const validateInterestOnly = (values: Record<any, any>) => {
    return {
        ...validateField(
            'mortgage_amount',
            'This field is required',
            isEmpty('mortgage_amount', values)
        ),
        ...validateField(
            'mortgage_term',
            'This field is required',
            isEmpty('mortgage_term', values)
        ),
        ...validateField(
            'mortgage_term',
            'Mortgage term must be between 1 and 40 years',
            isOutOfRange('mortgage_term', values, 1, 40)
        ),
        ...validateField(
            'mortgage_interest',
            'This field is required',
            isEmpty('mortgage_interest', values)
        ),
    }
};

export const validateCI = (values: Record<any, any>) => {
    return {
        ...validateField(
            'mortgage_amount',
            'This field is required',
            isEmpty('mortgage_amount', values)
        ),
        ...validateField(
            'mortgage_term',
            'This field is required',
            isEmpty('mortgage_term', values)
        ),
        ...validateField(
            'mortgage_term',
            'Mortgage term must be between 1 and 40 years',
            isOutOfRange('mortgage_term', values, 1, 40)
        ),
        ...validateField(
            'mortgage_interest',
            'This field is required',
            isEmpty('mortgage_interest', values)
        ),
    };
};