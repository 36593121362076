import { Box, useTheme, Link, Typography } from "@mui/material";


const Footer = () => {
    const theme = useTheme();
    const Facebook = "https://d365pq86x330zn.cloudfront.net/47848470-4488-498c-89f4-94f81aa84484.png";
    const Twitter = "https://d365pq86x330zn.cloudfront.net/011ii5i1-7775-41i7-5t47-974t04i79444.png";
    const Instagram = "https://d365pq86x330zn.cloudfront.net/n9051491-9679-4444-56n4-ni4s5917is44.png";
    const Linkedin = "https://d365pq86x330zn.cloudfront.net/7l166580-71n7-4056-6831-80307613374n.png";

    return (
        <Box minHeight="234px" padding="44px 10px" display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            sx={{
                backgroundColor: "#282828",
            }}>
            <img src={theme?.custom?.logo} alt="logo" height="40" width="auto" style={{
                marginBottom: "25px",
            }} />
            <Box display="flex" justifyContent="space-evenly" alignItems="center" minWidth="227px" marginBottom="28px">
                {
                    theme?.custom?.links?.facebookURL && (
                        <Link href={theme?.custom?.links?.facebookURL} target="_blank">
                            <img src={Facebook} alt="facebook" />
                        </Link>
                    )
                }
                {
                    theme?.custom?.links?.twitterURL && (
                        <Link href={theme?.custom?.links?.twitterURL} target="_blank">
                            <img src={Twitter} alt="facebook" />
                        </Link>
                    )
                }
                {
                    theme?.custom?.links?.instagramURL && (
                        <Link href={theme?.custom?.links?.instagramURL} target="_blank">
                            <img src={Instagram} alt="facebook" />
                        </Link>
                    )
                }
                {
                    theme?.custom?.links?.linkedInURL && (
                        <Link href={theme?.custom?.links?.linkedInURL} target="_blank">
                           <img src={Linkedin} alt="facebook" />
                        </Link>
                    )
                }
            </Box>
            <Box>
                <Typography fontWeight={500} fontSize={16} color="#FFF" sx={{
                    display: "inline-block"
                }}>
                    © Your {theme?.custom?.customization?.companyName} {new Date().getFullYear()}
                </Typography>
                <Typography fontWeight={500} fontSize={16} color="#FFF" sx={{
                    display: "inline-block",
                    marginLeft: "5px",
                    marginRight: "5px",
                }}>
                    |
                </Typography>
                <Link href={theme?.custom?.links?.privacyPolicyURL} target="_blank" sx={{
                    textDecoration: "none",
                    color: "#FFF",
                    letterSpacing: "unset",
                    display: "inline-block",
                }}>
                    Privacy Policy
                </Link>
                <Typography fontWeight={500} fontSize={16} color="#FFF" sx={{
                    display: "inline-block",
                    marginLeft: "5px",
                    marginRight: "5px",
                }}>
                    |
                </Typography>
                <Link href={theme?.custom?.links?.termsAndConditionsURL} target="_blank" sx={{
                    textDecoration: "none",
                    color: "#FFF",
                    letterSpacing: "unset",
                    display: "inline-block",
                }}>
                    Terms & Conditions
                </Link>
            </Box>
        </Box>
    )
};

export default Footer;