import { Grid, Typography, useTheme } from "@mui/material";
import DOMPurify from "dompurify";
import React, { useState } from "react";

import { AppConfig } from "../../App";
import Form, { FormConfig } from "../form";
import "./index.scss";
import NextSteps from "./next-steps";
import CalculatorTabs from "./tabs";

interface CalculatorHeading {
  heading: string;
  total: number;
  subTexts: string[];
  isInitialValues: boolean;
}

interface ICalculator {
  title: string;
  height: string;
  description: string;
  tabs: string[];
  headings: CalculatorHeading[];
  forms: FormConfig[][];
  disclaimers: string[];
  handleSubmit: (values: Record<any, any>, tabIndex: number) => void;
  validations: any[];
  config?: AppConfig;
}

const Calculator: React.FC<ICalculator> = ({
  title,
  height,
  description,
  tabs,
  headings,
  forms,
  disclaimers,
  handleSubmit,
  validations,
  config,
}) => {
  const isCalculatorOnly = config?.view === "calculator";
  const showTitle = config?.title;

  const theme = useTheme();

  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const renderCalculatorTabs = () => {
    return (
      <CalculatorTabs
        config={config!}
        tabs={tabs}
        activeTabIndex={activeTabIndex}
        setActiveTabIndex={(tabIndex) => setActiveTabIndex(tabIndex)}
      />
    );
  };

  const renderCalculator = () => {
    const calculatorHeading = headings[activeTabIndex];

    return (
      <div className="calculator-results-container">
        <div
          className="calculator-results"
          style={{
            backgroundColor: theme?.palette?.primary?.main,
          }}
        >
          <Typography className="calculator-results_heading">
            {calculatorHeading?.heading}
          </Typography>
          <Typography className="calculator-results_total">
            £{calculatorHeading.isInitialValues ? "0" : calculatorHeading?.total?.toLocaleString("en-GB", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
          </Typography>
          {calculatorHeading?.subTexts.map((text) => (
            <Typography
              className="calculator-results_text"
              key={text}
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text) }}
            />
          ))}
        </div>
        <NextSteps />
      </div>
    );
  };

  const renderForm = () => {
    return (
      <Form
        appConfig={config!}
        config={forms[activeTabIndex]}
        handleSubmit={(values) => handleSubmit(values, activeTabIndex)}
        validator={validations[activeTabIndex]}
      />
    );
  };

  return (
    <>
      {!isCalculatorOnly && (
        <>
          {showTitle === 'false' ? null : <Typography
            className="calculator-title" 
            style={{
              color: theme?.palette?.primary?.main,
            }}
          >
            {title}
          </Typography>}
          <Typography
            className="calculator-description"
          >
            {description}
          </Typography>
        </>
      )}
      {renderCalculatorTabs()}
      <Grid
        container
        marginBottom="33px"
        sx={{
          backgroundColor:
            theme?.custom?.apiKey === "3e1e4b3b-923f-4aad-91b3-3a0d562e05ee" ||
            theme?.custom?.apiKey === "f74e7819-6147-400f-9910-3aed25aef243"
              ? "#abb8c3"
              : "#F6F7F2",
          borderRadius: "8px",
          minHeight: height,
          ...(!tabs[activeTabIndex]?.length
            ? {
                marginTop: "33px",
              }
            : {}),
          "@media screen and (max-width: 900px)": {
            height: "unset",
          },
        }}
      >
        <Grid
          item
          sm={12}
          md={6}
          padding="45px 26px"
          sx={{
            "@media screen and (max-width: 767px)": {
              padding: "10px",
              width: "100%",
            },
          }}
        >
          {renderForm()}
        </Grid>
        <Grid
          item
          sm={12}
          md={6}
          padding="45px 26px"
          sx={{
            "@media screen and (max-width: 767px)": {
              padding: "10px",
              width: "100%",
            },
          }}
        >
          {renderCalculator()}
        </Grid>
      </Grid>
      {theme?.custom?.apiKey !== "3e1e4b3b-923f-4aad-91b3-3a0d562e05ee" &&
        theme?.custom?.apiKey !== "f74e7819-6147-400f-9910-3aed25aef243" &&
        disclaimers.map((disclaimer) => (
          <Typography
            key={disclaimer}
            className="calculator-disclaimer"
          >
            {disclaimer}
          </Typography>
        ))}
    </>
  );
};

export default Calculator;
