import dot from 'dot-object'

export const isEmpty = (fieldName: string, values: any) =>
    !dot.pick(fieldName, values)

export const isOutOfRange = (field: string, values: Record<any, any>, min: number, max: number) => {
    const value = Number(values[field]);
    return isNaN(value) || value < min || value > max;
};

export const validateField = (
    fieldName: string,
    errorMessage: string,
    customValidator: boolean = false
) => {
    const errors: Record<any, string> = {}

    if (customValidator) {
        errors[fieldName] = errorMessage
    }

    return errors;
}
