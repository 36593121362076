import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { AppConfig } from "../../App";
import {
    Accordion,
    Banner,
    Calculator,
    Footer,
    Header,
} from "../../components";
import HowMuchCanIBorrowInfo from "../../components/accordion/how-much-can-i-borrow";
import HowMuchCanPeopleBorrowWithBadCredit from "../../components/accordion/how-much-can-i-borrow-bad-credit";
import HowMuchCanIBorrowWithNoDeposit from "../../components/accordion/how-much-can-i-borrow-no-deposit";
import HowMuchMortgageCanIAfford from "../../components/accordion/how-much-mortgage-can-i-afford";
import HowMuchPeopleBorrow from "../../components/accordion/how-much-people-borrow";
import ShouldIBorrowMaximumAmount from "../../components/accordion/should-i-borrow-maximum-amount";
import { validateCI, validateInterestOnly } from "./validator";

const MortgagePayment = ({
    apiKey,
    config,
}: {
    apiKey: any;
    config: AppConfig;
}) => {
    const formFields = [
        {
            formType: "input",
            inputConfig: {
                label: "Mortgage amount",
                name: "mortgage_amount",
                type: "numeric",
                info: "This will be the size of the mortgage. You’ll need to pay it back, along with interest. The borrowing amount doesn’t include your deposit.",
                startAdornment: "£",
                ...(config?.mortgageAmount
                    ? {
                          value: config?.mortgageAmount!,
                      }
                    : {}),
            },
        },
        {
            formType: "input",
            inputConfig: {
                label: "Mortgage term (years)",
                name: "mortgage_term",
                type: "numeric",
                ...(config?.mortgageTerm
                    ? {
                          value: config?.mortgageTerm!,
                      }
                    : {}),
                info: "The mortgage term is how many years you have left on your mortgage. Try out different mortgage terms to see how they affect your monthly repayments and total interest paid",
            },
        },
        {
            formType: "input",
            inputConfig: {
                label: "Mortgage interest rate (%)",
                name: "mortgage_interest",
                type: "numeric",
                ...(config?.mortgageInterest
                    ? {
                          value: config?.mortgageInterest!,
                      }
                    : {}),
                info: "This is the rate of interest you’ll pay on your mortgage. Higher interest rates will generally(but not always) increase your monthly payments, depending on the deal.",
                endAdornment: "%",
            },
        },
    ] as any;

    const isCalculatorOnly = config?.view === "calculator";
    const header = config?.header;
    const footer = config?.footer;
    const faq = config?.faq;

    const [payment, setPayment] = useState({
        interestOnly: 0,
        ci: 0,
        isInitialValues: true,
    });

    const handleSubmit = (values: Record<any, any>, activeTab: number) => {
        const mortgageAmount = parseFloat(values?.mortgage_amount);
        const mortgageInterest = parseFloat(values?.mortgage_interest) / 100;
        const mortgageTerm = parseInt(values?.mortgage_term);

        if (activeTab === 1) {
            setPayment({
                ...payment,
                interestOnly: parseFloat(
                    ((mortgageAmount * mortgageInterest) / 12).toFixed(2)
                ),
                isInitialValues: false,
            });
        } else if (activeTab === 0) {
            const ir = mortgageInterest / 12;
            const np = mortgageTerm * 12;
            const pv = mortgageAmount;
            const pvif = Math.pow(1 + ir, np);
            const pmt = (ir * (pv * pvif)) / (pvif - 1);
            setPayment({
                ...payment,
                ci: parseFloat(pmt.toFixed(2)),
                isInitialValues: false,
            });
        }
        if (apiKey === "e2d09442-8603-419f-92eb-685f13bf1f8a") {
            //@ts-ignore
            window.dataLayer = window.dataLayer || [];
            //@ts-ignore
            window.dataLayer.push({
                event: "mortgage_calculator",
                action: "calculate",
            });

            console.log(`//@ts-ignore
            window.dataLayer = window.dataLayer || [];
            //@ts-ignore
            window.dataLayer.push({
                event: "mortgage_calculator",
                action: "calculate",
            });`);

            console.log(
                `window.dataLayer: `,
                //@ts-ignore
                window.dataLayer
            );
        }
    };

    const renderAccordion = () => {
        return (
            <Box paddingTop="79px" paddingBottom="91px">
                <Typography className="your-question-answered">
                    Your question answered
                </Typography>
                <Box marginTop="33px">
                    <Accordion
                        title="How much can I borrow for my mortgage?"
                        content={<HowMuchCanIBorrowInfo />}
                    />
                    <Accordion
                        title="How much mortgage can I afford?"
                        content={<HowMuchMortgageCanIAfford />}
                    />
                    <Accordion
                        title="Should I borrow the maximum amount?"
                        content={<ShouldIBorrowMaximumAmount />}
                    />
                    <Accordion
                        title="How much do most people borrow?"
                        content={<HowMuchPeopleBorrow />}
                    />
                    <Accordion
                        title="How much can I borrow with bad credit?"
                        content={<HowMuchCanPeopleBorrowWithBadCredit />}
                    />
                    <Accordion
                        title="How much can I borrow with no deposit?"
                        content={<HowMuchCanIBorrowWithNoDeposit />}
                    />
                </Box>
            </Box>
        );
    };

    const renderCalculator = () => (
        <Calculator
            config={config}
            height="480px"
            title="Mortgage Calculator"
            description="Use our Calculator to see what your mortgage repayments could be when it comes to moving house, remortgaging, or buying your first home."
            headings={[
                {
                    heading: "Your monthly mortgage repayment",
                    total: payment.ci,
                    subTexts: [],
                    isInitialValues: payment?.isInitialValues,
                },
                {
                    heading: "Your monthly mortgage repayment",
                    total: payment.interestOnly,
                    subTexts: [],
                    isInitialValues: payment?.isInitialValues,
                },
            ]}
            tabs={["Capital & Interest Calculator", "Interest Only Calculator"]}
            forms={[formFields, formFields]}
            disclaimers={[
                "Your home could be repossessed if you don't keep up repayments on your mortgage.",
                "You may have to pay an early repayment charge if you remortgage.",
            ]}
            validations={[validateCI, validateInterestOnly]}
            handleSubmit={handleSubmit}
        />
    );

    return (
        <>
            {!isCalculatorOnly && !header && <Header />}
            {!isCalculatorOnly && !header && (
                <Banner
                    bannerTitle="Mortgage Payment Calculator"
                    bannerSubtitle="See how much your monthly payments could be"
                    align="left"
                    bannerHelpText="Your home could be repossessed if you don't keep up repayments on your mortgage."
                />
            )}
            <Box
                maxWidth="1014px"
                margin="auto"
                padding={!isCalculatorOnly && !header ? "20px" : "20px"}
            >
                {renderCalculator()}
                {!isCalculatorOnly && !faq ? renderAccordion() : null}
            </Box>
            {!isCalculatorOnly && !footer && <Footer />}
        </>
    );
};

export default MortgagePayment;
