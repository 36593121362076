import { Box, Typography, Button, useTheme, } from '@mui/material';
import { hexToRgb } from '../../util';

interface IBanner {
    bannerTitle: string;
    bannerSubtitle: string;
    bannerHelpText?: string;
    align?: string;
};

const Banner: React.FC<IBanner> = ({
    bannerTitle,
    bannerSubtitle,
    align,
    bannerHelpText,
}) => {
    const theme = useTheme();

    return (
        <Box
            minHeight="292px"
            display="flex"
            alignItems="left"
            justifyContent="center"
            flexDirection="column"
            sx={{
                backgroundColor: theme?.palette?.primary?.main,
                '@media screen and (max-width: 767px)': {
                    padding: '10px',
                }
            }}>
            <Box maxWidth="1014px" margin="auto" width="100%" display="flex" flexDirection="column" alignItems={align ? align : "center"}>
                <Typography fontSize={56} fontWeight={600} lineHeight="68.26px" color="#FFF" fontFamily="'Montserrat', sans-serif">
                    {bannerTitle}
                </Typography>
                <Typography fontSize={21} fontWeight={600} lineHeight="28.6px" color="#FFF">
                    {bannerSubtitle}
                </Typography>
                <Button href={theme?.custom?.links?.getStartedURL!} target="_blank"
                    disableRipple
                    disableElevation
                    color="primary"
                    variant="contained" sx={{
                        backgroundColor: '#282828',
                        borderRadius: "4px",
                        marginTop: "17px",
                        padding: "13px 76px",
                        maxWidth: "320px",
                        boxSizing: "border-box",
                        marginBottom: "20px",
                        '&:hover': {
                            backgroundColor: `#282828`,
                        }
                    }}>
                    <Typography component="span"
                        fontWeight={600}
                        fontSize={16}
                        lineHeight="21.79px" sx={{
                            textTransform: "capitalize",
                        }}>
                        Get started online
                    </Typography>
                </Button>
                {
                    bannerHelpText && (
                        <Typography marginTop="12px" fontSize={16} fontWeight={400} lineHeight="28.6px" color="#FFF">
                            {bannerHelpText}
                        </Typography>
                    )
                }
            </Box>
        </Box >
    )
}

export default Banner;