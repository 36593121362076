import { isEmpty, validateField } from "../validator";

export const validateInterestOnly = (values: Record<any, any>) => {
    return {
        ...validateField(
            'mortgage_amount',
            'This field is required',
            isEmpty('mortgage_amount', values)
        ),
        ...validateField(
            'mortgage_term',
            'This field is required',
            isEmpty('mortgage_term', values)
        ),
        ...validateField(
            'mortgage_interest',
            'This field is required',
            isEmpty('mortgage_interest', values)
        ),
    }
};

export const validateCI = (values: Record<any, any>) => {
    return {
        ...validateField(
            'mortgage_amount',
            'This field is required',
            isEmpty('mortgage_amount', values)
        ),
        ...validateField(
            'mortgage_term',
            'This field is required',
            isEmpty('mortgage_term', values)
        ),
        ...validateField(
            'mortgage_interest',
            'This field is required',
            isEmpty('mortgage_interest', values)
        ),
    };
};