import { Box, InputAdornment, InputBase, Tooltip, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { Field } from 'react-final-form';

import { numberWithCommas } from '../../util';

interface ITextField {
    hasstartadornment?: string;
    hasendadornment?: string;
}

const InfoIcon = 'https://d365pq86x330zn.cloudfront.net/74721118-3110-4141-11i1-71f015n70715.png';

const TextField = styled(InputBase)<ITextField>(({ theme, hasstartadornment, hasendadornment, }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    width: '100%',
    '&.MuiInputBase-root': {
        backgroundColor: '#FFF',
        border: '2px solid #E3E0DB',
    },
    '& .MuiInputAdornment-positionStart': {
        marginTop: '0px !important',
        '> p': {
            fontSize: 24,
            fontWeight: 700,
            color: '#000',
            ...(hasstartadornment === "true" && {
                marginLeft: '23px',
            }),
        }
    },
    '& .MuiInputAdornment-positionEnd': {
        marginTop: '0px !important',
        '> p': {
            fontSize: 24,
            fontWeight: 700,
            marginTop: '0px',
            color: '#000',
            ...(hasendadornment === "true" && {
                marginRight: '23px',
            }),
        }
    },
    '& .MuiInputBase-input': {
        borderRadius: "4px",
        paddingTop: "13px",
        paddingBottom: "13px",
        paddingLeft: hasstartadornment === "true" ? "0px" : "23px",
        paddingRight: hasendadornment === "true" ? "0px" : "23px !important",
        position: 'relative',
        backgroundColor: '#FFF',
        border: 'none',
        fontSize: 24,
        fontWeight: 700,
        width: '100%',
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
        // Use the system font instead of the default Roboto font.
        fontFamily: ['Montserrat', 'sans-serif'].join(','),
        '&.Mui-disabled': {
            backgroundColor: '#f4f4f4',
        },
    },
}));

export interface IInput {
    name: string;
    type: any;
    label: string;
    placeholder?: string;
    endAdornment?: string;
    startAdornment?: string;
    info?: string;
    value?: string;
};

const Input: React.FC<IInput> = ({
    name, type, placeholder, endAdornment, startAdornment, label, info,
    value,
}) => {
    const theme = useTheme();
   
    return (
        <Box marginBottom="23px">
            <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="10px">
                <Typography className="form-label">
                    {label}
                </Typography>
                
                {
                    info ? (
                        <Tooltip title={info} placement="right">
                            <img src={InfoIcon} style={{
                                cursor: 'pointer',
                                color: theme?.palette?.primary?.main
                            }} alt="info" />
                        </Tooltip>
                    ) : null
                }
            </Box>
            <Field name={name} component="input"
                initialValue={value}
                format={value => value && type === 'numeric' ? numberWithCommas(value) : value}
                parse={value => value.replace(/,/g, '')}
            >
                {
                    ({ input: { value, name, onChange } }) => (
                        <TextField
                            name={name}
                            value={value}
                            placeholder={placeholder}
                            inputMode={type}
                            inputProps={{
                                lang: "en-GB",
                            }}
                            hasstartadornment={startAdornment !== undefined ? "true" : "false"}
                            hasendadornment={endAdornment !== undefined ? "true" : "false"}
                            endAdornment={
                                endAdornment && (
                                    <InputAdornment variant="filled" position="end">{endAdornment}</InputAdornment>
                                )
                            }
                            startAdornment={
                                startAdornment && (
                                    <InputAdornment variant="filled" position="start">{startAdornment}</InputAdornment>
                                )
                            }
                            onChange={onChange}
                        />
                    )
                }
            </Field>
        </Box>
    )
};

export default Input;