import { Typography } from '@mui/material';

const HowMuchCanPeopleBorrowWithBadCredit = () => (
    <>
        <Typography component="p" fontWeight={400} fontSize={16} lineHeight="21.79px">
            Even if you’ve got a poor credit score now the amount you can borrow still depends on your personal situation.
        </Typography>
        <Typography marginTop="20px" fontWeight={400} fontSize={16} lineHeight="21.79px">
            A lender looks at your credit history to see how well you’ve managed debt before. This is known as a credit check.
        </Typography>
        <Typography marginTop="20px" fontWeight={400} fontSize={16} lineHeight="21.79px">
            If you have bad credit history some lenders may:
        </Typography>
        <ul>
            <li>
                <Typography fontWeight={400} fontSize={16} lineHeight="21.79px">
                    turn you down for a mortgage
                </Typography>
            </li>
            <li>
                <Typography fontWeight={400} fontSize={16} lineHeight="21.79px">
                    ask for a bigger deposit
                </Typography>
            </li>
            <li>
                <Typography fontWeight={400} fontSize={16} lineHeight="21.79px">
                    offer you a higher interest rate
                </Typography>
            </li>
        </ul>
        <Typography marginTop="20px" fontWeight={400} fontSize={16} lineHeight="21.79px">
            Whether your credit history will affect your mortgage application depends on:
        </Typography>
        <ul>
            <li>
                <Typography fontWeight={400} fontSize={16} lineHeight="21.79px">
                    what the credit problem is
                </Typography>
            </li>
            <li>
                <Typography fontWeight={400} fontSize={16} lineHeight="21.79px">
                    the amount
                </Typography>
            </li>
            <li>
                <Typography fontWeight={400} fontSize={16} lineHeight="21.79px">
                    when it happened
                </Typography>
            </li>
        </ul>
        <Typography marginTop="20px" fontWeight={400} fontSize={16} lineHeight="21.79px">
            Missing a mortgage payment or going bankrupt are two scenarios that can stay on your credit report for six years. Missing payment on a loan is clearly more serious than missing a utility bill payment, for example, and as such can harm your credit score more severely.
        </Typography>
        <Typography marginTop="20px" fontWeight={400} fontSize={16} lineHeight="21.79px">
            It’s possible to explain to a lender how you got into debt, however.
        </Typography>
        <Typography marginTop="20px" fontWeight={400} fontSize={16} lineHeight="21.79px">
            For example, if your finances are normally well-managed, but your debt is a result of a life event such as divorce, a lender might not view your debt as seriously.
        </Typography>
        <Typography marginTop="20px" fontWeight={400} fontSize={16} lineHeight="21.79px">
            You should always speak to a mortgage broker to find out how your situation could affect how much mortgage you can borrow.
        </Typography>
    </>
);

export default HowMuchCanPeopleBorrowWithBadCredit;