import { Box, Typography, useTheme } from "@mui/material";
import { useRef, useState } from "react";

import { hexToRgb } from "../../util";

import "./index.scss";

interface IAccordion {
    title: string;
    content: React.ReactNode;
}

const Accordion: React.FC<IAccordion> = ({ title, content }) => {
    const theme = useTheme();
    const accordionRef = useRef<HTMLElement>(null);
    const [show, setShow] = useState(false);
    const ArrowDown =
        "https://d365pq86x330zn.cloudfront.net/09190a46-a411-4776-9r1r-661r7rr4671r.png";
    const ArrowDownWhite =
        "https://d365pq86x330zn.cloudfront.net/36999163-2931-4929-2969-192310033396.png";
    const ArrowUp =
        "https://d365pq86x330zn.cloudfront.net/33a71310-411r-437r-0470-13717a4ra377.png";
    const ArrowUpWhite =
        "https://d365pq86x330zn.cloudfront.net/73832383-2727-4a73-7331-32a279700270.png";

    return (
        <>
            <Box
                ref={accordionRef}
                className="t7t-accordion"
                sx={{
                    backgroundColor: theme?.palette?.primary?.main,
                    borderBottom: `1px solid rgba(${hexToRgb(
                        theme?.palette?.primary?.main
                    )?.r!},${hexToRgb(theme?.palette?.primary?.main)
                        ?.g!},${hexToRgb(theme?.palette?.primary?.main)
                        ?.b!}, 0.8)`,
                }}
                onClick={() => {
                    if (accordionRef) {
                        const panel = accordionRef.current
                            ?.nextSibling as HTMLElement;
                        if (panel.classList.contains("active")) {
                            panel.classList.remove("active");
                            accordionRef.current?.classList.remove("active");
                            accordionRef.current!.style.borderBottom = `1px solid ${theme?.palette?.primary?.main}`;
                            setShow(false);
                        } else {
                            panel.classList.add("active");
                            accordionRef.current?.classList.add("active");
                            accordionRef.current!.style.borderBottom = `none`;
                            setShow(true);
                        }
                    }
                }}
            >
                <Typography
                    fontWeight={400}
                    fontSize={21}
                    color="#FFF"
                    lineHeight="28.6px"
                >
                    {title}
                </Typography>
                {show ? (
                    theme.custom.apiKey ===
                    "cc45e8c9-48f2-46ea-97aa-5bc8b1092cb2" ? (
                        <img src={ArrowUpWhite} alt="arrow-up" />
                    ) : (
                        <img src={ArrowUp} alt="arrow-up" />
                    )
                ) : theme.custom.apiKey ===
                  "cc45e8c9-48f2-46ea-97aa-5bc8b1092cb2" ? (
                    <img src={ArrowDownWhite} alt="arrow-down" />
                ) : (
                    <img src={ArrowDown} alt="arrow-down" />
                )}
            </Box>
            <Box
                className="t7t-panel"
                sx={{
                    backgroundColor: `rgba(${hexToRgb(
                        theme?.palette?.primary?.main
                    )?.r!},${hexToRgb(theme?.palette?.primary?.main)
                        ?.g!},${hexToRgb(theme?.palette?.primary?.main)
                        ?.b!}, 0.8) !important`,
                }}
            >
                <Box className="content" sx={{ color: "#FFF" }}>
                    {content}
                </Box>
            </Box>
        </>
    );
};

export default Accordion;
