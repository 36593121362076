import { isEmpty, validateField } from '../validator';

export const validateStampDuty = (values: Record<any, any>) => {
    return {
        ...validateField(
            'property_price',
            'This field is required',
            isEmpty('property_price', values)
        ),
        ...validateField(
            'where_are_you_buying',
            'This field is required',
            isEmpty('where_are_you_buying', values)
        ),
        ...validateField(
            'first_time_buyer',
            'This field is required',
            isEmpty('first_time_buyer', values)
        ),
        // ...validateField(
        //     'only_property',
        //     'This field is required',
        //     isEmpty('only_property', values),
        // ),
    };
};