
import { AppConfig } from '../../../App';
import './index.scss';

interface ITabs {
    tabs: string[];
    activeTabIndex: number;
    setActiveTabIndex: React.Dispatch<any>;
    config: AppConfig
}

const CalculatorTabs: React.FC<ITabs> = ({
    tabs,
    activeTabIndex,
    setActiveTabIndex,
    config,
}) => {
    const isCalculatorOnly = config.view === 'calculator';

    return tabs.length > 1 ? (
        <div className='calculator-tabs' style={{
            ...(isCalculatorOnly && {
                marginTop: '0px',
            })
        }}>
            {
                tabs.map((tab, index) => (
                    <div key={tab} className={`calculator-tab ${index === activeTabIndex ? "active" : ""}`}
                        onClick={() => setActiveTabIndex(index)}>
                        {tab}
                    </div>
                ))
            }
        </div>
    ) : null
};

export default CalculatorTabs;