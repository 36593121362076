import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import { CssBaseline, ThemeProvider } from "@mui/material";
import { HowMuchCanIBorrow, MortgagePayment, StampDuty } from "./pages";

import platform from "platform";
import { ThemeData } from ".";
import { initializeTheme } from "./theme";
import { initializeGoogleAnalytics, trackEvent } from "./util";

export interface AppConfig {
    header?: boolean;
    footer?: boolean;
    title?: string;
    faq?: boolean;
    view?: string;
    autoCalculate?: boolean;
    propertyPrice?: number;
    buyingLocation?: string;
    firstTimeBuyer?: boolean;
    onlyProperty?: boolean;
    mortgageAmount?: number;
    mortgageInterest?: number;
    mortgageTerm?: number;
    annualSalary?: number;
    partnerAnnualSalary?: number;
    depositAmount?: number;
    monthlyOutgoings?: number;
}

interface Props {
    apiKey?: string;
    config?: AppConfig;
}

const { REACT_APP_SHOP_URL } = process.env;

function App(props: Props) {
    const [theme, setTheme] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [version, setVersion] = useState("");
    const [themeData, setThemeData] = useState<ThemeData | null>();

    const renderPage = () => {
        switch (version) {
            case "stamp-duty":
                return <StampDuty config={props?.config!} />;
            case "mortgage-payment":
                return (
                    <MortgagePayment
                        config={props?.config!}
                        apiKey={props?.apiKey!}
                    />
                );
            case "how-much-can-i-borrow":
                return <HowMuchCanIBorrow config={props?.config!} />;
            default:
                return null;
        }
    };

    const getDefaultColors = (themeId: string) => {
        switch (themeId) {
            case "blue":
                return {
                    primaryColor: "#002554",
                    secondaryColor: "#fdf8f3",
                };
            case "brown":
                return {
                    primaryColor: "#b1a296",
                    secondaryColor: "#dff1ff",
                };
            case "green":
                return {
                    primaryColor: "#41b3a3",
                    secondaryColor: "#fff0e6",
                };
            default:
                return {
                    primaryColor: "#ff385c",
                    secondaryColor: "#464646",
                };
        }
    };

    const getProductData = useCallback(async () => {
        try {
            const apiKey = props?.apiKey!;
            const { data } = await axios.get(
                `${REACT_APP_SHOP_URL}/api/v1/shop/client/get?apiKey=${apiKey}`
            );

            const {
                email,
                logo,
                name,
                attributes: { theme: themeId, version },
                emailCustomization,
                links,
                googleAnalyticsTrackingId,
                appointmentCustomization,
            } = data;

            setVersion(version);
            setThemeData({
                apiKey,
                themeId,
                email,
                logo,
                name,
                version,
                customization: {
                    fontFamily: data?.customization?.fontFamily,
                    primaryColor:
                        data?.customization?.primaryColor ||
                        getDefaultColors(themeId)?.primaryColor,
                    secondaryColor:
                        data?.customization?.secondaryColor ||
                        data?.customization?.primaryColor ||
                        getDefaultColors(themeId)?.secondaryColor,
                    companyName:
                        data?.customization?.companyName ||
                        data?.companyName ||
                        "Meet Parker",
                },
                emailCustomization,
                appointmentCustomization,
                links,
                googleAnalyticsTrackingId,
            });
            if (
                Array.isArray(googleAnalyticsTrackingId) &&
                googleAnalyticsTrackingId.length
            ) {
                initializeGoogleAnalytics(googleAnalyticsTrackingId);
            }

            await trackEvent(apiKey!, {
                pageVisit: {
                    deviceBrowser: platform.name,
                    deviceMobile: platform.product,
                    deviceOs: platform.os?.toString(),
                },
                pluginType: version,
            });
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        const checkAndAddGTM = () => {
            if (props.apiKey === "e2d09442-8603-419f-92eb-685f13bf1f8a") {
                const script = document.createElement("script");
                script.async = true;
                script.innerHTML = `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-KML8KF3K');
        `;
                document.head.appendChild(script);
            }
        };

        checkAndAddGTM();
    }, [props.apiKey]);

    useEffect(() => {
        getProductData();
    }, [getProductData]);

    return isLoading ? null : (
        <>
            {props.apiKey === "e2d09442-8603-419f-92eb-685f13bf1f8a" && (
                <Helmet>
                    <noscript>
                        {`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KML8KF3K"
            height="0" width="0" style={{ display: 'none', visibility: 'hidden' }}></iframe>`}
                    </noscript>
                </Helmet>
            )}
            <ThemeProvider theme={initializeTheme(themeData!)}>
                <CssBaseline />
                {renderPage()}
            </ThemeProvider>
        </>
    );
}

export default App;
