import { renderApp } from '.';

const widgetDivs = document.querySelectorAll('.mp-calculator-plugin');
widgetDivs.forEach((div: any) => {
    const apiKey = div.dataset.apikey;
    const config = {
        header: div.dataset.header,
        footer: div.dataset.footer,
        title: div.dataset.title,
        faq: div.dataset.faq,
        view: div.dataset.view,
        autoCalculate: div.dataset.autocalculate,
        propertyPrice: div.dataset.propertyprice,
        buyingLocation: div.dataset.buyinglocation,
        firstTimeBuyer: div.dataset.firstimebuyer,
        onlyProperty: div.dataset.onlyproperty,
        mortgageAmount: div.dataset.mortgageamount,
        mortgageInterest: div.dataset.mortgageinterest,
        mortgageTerm: div.dataset.mortgageterm,
        annualSalary: div.dataset.annualsalary,
        partnerAnnualSalary: div.dataset.partnerannualsalary,
        depositAmount: div.dataset.depositamount,
        monthlyOutgoings: div.dataset.monthlyoutgoings,
    };

    renderApp(apiKey, config as any, div);
});

const createCalculatorDiv = (htmlElement: HTMLElement): HTMLElement => {
  if (htmlElement) {
    return htmlElement;
  }

  const element = document.createElement("div");
  element.id = "mp-calculator";

  document.body.appendChild(element);

  return element;
};

const isChatAppExisting = () => {
  return document.querySelector("#mp-calculator") !== null;
};

const MeetParkerCalculator = {
  init: (apiKey: string, config: any = {}, element: HTMLElement) => {
    if (isChatAppExisting()) {
      document.body.removeChild(document.querySelector("#mp-calculator")!);
    }
    renderApp(apiKey, config as any, createCalculatorDiv(element));
  },
};

export default MeetParkerCalculator;