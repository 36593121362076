import { Typography } from '@mui/material';

const HowMuchPeopleBorrow = () => (
    <>
        <Typography component="p" fontWeight={400} fontSize={16} lineHeight="21.79px">
            According to our data, most people who get a mortgage to buy a property borrow between 2 and 4 times their income.
        </Typography>
        <Typography marginTop="20px" fontWeight={400} fontSize={16} lineHeight="21.79px">
            Generally, the average loan-to-income (LTI) ratio is higher in the south of the country where houses are more expensive.
        </Typography>
    </>
);

export default HowMuchPeopleBorrow;