import { Typography } from '@mui/material';

const HowMuchCanIBorrowInfo = () => (
    <>
        <Typography fontWeight={400} fontSize={16} lineHeight="21.79px">
            You can usually borrow around 4 to 5 times your salary.
        </Typography>
        <Typography marginTop="20px" fontWeight={400} fontSize={16} lineHeight="21.79px">
            Some lenders offer up to 6 times your salary, but they will be very strict about who they lend this amount to. Lenders also have different rules and the income multiple they allow can depend on many things.
        </Typography>
        <Typography marginTop="20px" fontWeight={400} fontSize={16} lineHeight="21.79px">
            They include:
        </Typography>
        <ul>
            <li>
                <Typography fontWeight={400} fontSize={16} lineHeight="21.79px">
                    salary and source of income
                </Typography>
            </li>
            <li>
                <Typography fontWeight={400} fontSize={16} lineHeight="21.79px">
                    using a government homeownership scheme
                </Typography>
            </li>
            <li>
                <Typography fontWeight={400} fontSize={16} lineHeight="21.79px">
                    extra benefits (for example, Barclays offer Premier customers slightly higher income multiples)
                </Typography>
            </li>
            <li>
                <Typography fontWeight={400} fontSize={16} lineHeight="21.79px">
                    deposit size
                </Typography>
            </li>
            <li>
                <Typography fontWeight={400} fontSize={16} lineHeight="21.79px">
                    financial commitments and bills
                </Typography>
            </li>
            <li>
                <Typography fontWeight={400} fontSize={16} lineHeight="21.79px">
                    age
                </Typography>
            </li>
            <li>
                <Typography fontWeight={400} fontSize={16} lineHeight="21.79px">
                    length of mortgage
                </Typography>
            </li>
            <li>
                <Typography fontWeight={400} fontSize={16} lineHeight="21.79px">
                    leasehold costs
                </Typography>
            </li>
        </ul>
    </>
);

export default HowMuchCanIBorrowInfo;