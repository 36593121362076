import { Typography } from '@mui/material';

const WhatIsStampDuty = () => (
    <>
        <Typography fontWeight={400} fontSize={16} lineHeight="21.79px">
            Stamp Duty Land Tax (SDLT) is a tax in England and Northern Ireland. It usually applies to residential properties, or pieces of land, that cost more than £250,000.
        </Typography>
        <Typography marginTop="20px" fontWeight={400} fontSize={16} lineHeight="21.79px">
            Scotland and Wales have their own taxes that are equal to stamp duty.
        </Typography>
        <Typography marginTop="20px" fontWeight={400} fontSize={16} lineHeight="21.79px">
            You'll pay:
        </Typography>
        <ul>
            <li>
                <Typography fontWeight={400} fontSize={16} lineHeight="21.79px">
                    Land and Buildings Transaction Tax (LBTT) in Scotland
                </Typography>
            </li>
            <li>
                <Typography fontWeight={400} fontSize={16} lineHeight="21.79px">
                    Land Transaction Tax (LTT) in Wales
                </Typography>
            </li>
        </ul>
        <Typography marginTop="20px" fontWeight={400} fontSize={16} lineHeight="21.79px">
            Who has to pay stamp duty
        </Typography>
        <Typography marginTop="20px" fontWeight={400} fontSize={16} lineHeight="21.79px">
            Most buyers have to pay stamp duty. Aside from your mortgage, stamp duty could be the biggest cost of buying a home.
        </Typography>
    </>
);

export default WhatIsStampDuty;