import { Typography } from '@mui/material';

const WhenToPayStampDuty = () => (
    <>
        <Typography fontWeight={400} fontSize={16} lineHeight="21.79px">
            You have to pay your stamp duty within 14 days of your move in date. If it’s not paid by then, you could risk a fine, or having interest added to the amount due.
        </Typography>
        <Typography marginTop="20px" fontWeight={400} fontSize={16} lineHeight="21.79px">
            Often your solicitor deals with your stamp duty payment.
        </Typography>
        <Typography marginTop="20px" fontWeight={400} fontSize={16} lineHeight="21.79px">
            It can help to know what stamp duty is, how it’s calculated, and when it’s due.
        </Typography>
        <Typography marginTop="20px" fontWeight={400} fontSize={16} lineHeight="21.79px">
            You pay stamp duty as a lump sum on new properties, second homes, freehold and leasehold properties. It does not matter if you buy the home with a mortgage or with cash.
        </Typography>
    </>
);

export default WhenToPayStampDuty;