import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import reportWebVitals from './reportWebVitals';

import App, { AppConfig } from './App';

import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'

export interface ThemeData {
  logo: string;
  email: string;
  customization: {
    primaryColor: string;
    secondaryColor: string;
    companyName: string;
    fontFamily: string;
  };
  links: {
    contactUsURL?: string;
    privacyPolicyURL?: string;
    termsAndConditionsURL?: string;
    trustPilotURL?: string;
    cookieURL?: string;
    facebookURL?: string;
    twitterURL?: string;
    instagramURL?: string;
    linkedInURL?: string;
    getStartedURL?: string;
    compareDealsURL?: string;
  };
  name: string;
  themeId: string;
  emailCustomization: any;
  appointmentCustomization: any;
  googleAnalyticsTrackingId: any;
  apiKey: string;
  version: string;
}

declare module '@mui/material/styles' {
  interface Theme {
    custom: ThemeData;
  }
}

if (document.getElementById('root') as HTMLElement) {
  const urlParams = new URLSearchParams(document.location.search);

  const apiKey = urlParams?.get("apiKey");
  const config = {
    header: urlParams?.get('header')! ,
    footer: urlParams?.get('footer')!,
    title: urlParams?.get('title')!,
    faq: urlParams?.get('faq')!,
    view: urlParams?.get('view')!,
    autoCalculate: urlParams?.get('autoCalculate')!,
    propertyPrice: urlParams?.get('propertyPrice')!,
    buyingLocation: urlParams?.get('buyingLocation')!,
    firstTimeBuyer: urlParams?.get('firstTimeBuyer')!,
    onlyProperty: urlParams?.get('onlyProperty')!,
    mortgageAmount: urlParams?.get('mortgageAmount')!,
    mortgageInterest: urlParams?.get('mortgageInterest')!,
    mortgageTerm: urlParams?.get('mortgageTerm')!,
    annualSalary: urlParams?.get('annualSalary')!,
    partnerAnnualSalary: urlParams?.get('partnerAnnualSalary')!,
    depositAmount: urlParams?.get('depositAmount')!,
    monthlyOutgoings: urlParams?.get('monthlyOutgoings')!,
  };

  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );
  root.render(
    <BrowserRouter>
      <Routes>
        <Route path="/client" element={<App apiKey={apiKey!} config={config! as any} />} />
        <Route path="/404" element={<div>Page Not Found!</div>} />
        <Route path="*" element={<Navigate replace to="/404" />} />
      </Routes>
    </BrowserRouter>
  );  
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

const renderApp = (apiKey: string, appConfig: AppConfig, element: HTMLElement) => {
  if (element) {
    const rootDiv = ReactDOM.createRoot(element);

    rootDiv.render(
      <React.StrictMode>
        <App apiKey={apiKey} config={appConfig} />
      </React.StrictMode>
    )
  } else {
    throw new Error('Element does not exist.')
  }
};

export { renderApp };