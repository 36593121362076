import { Box, Typography } from '@mui/material';

import { Accordion, Banner, Calculator, Footer, Header } from '../../components'
import WhatIsStampDuty from '../../components/accordion/what-is-stamp-duty';
import WhenToPayStampDuty from '../../components/accordion/when-to-pay-stamp-duty';
import WhoDontPayStampDuty from '../../components/accordion/who-dont-pay-stamp-duty';
import HowToUseStampDuty from '../../components/accordion/how-to-use-stamp-duty';
import { validateStampDuty } from './validator';
import { useState } from 'react';
import { AppConfig } from '../../App';

const StampDuty = ({config}: { config: AppConfig }) => {

    const isCalculatorOnly = config?.view === 'calculator';
    const header = config?.header;
    const footer = config?.footer;
    const faq = config?.faq;

    const formFields = [{
        formType: "input",
        inputConfig: {
            label: "What is the property price?",
            name: "property_price",
            type: "numeric",
            startAdornment: "£",
            ...(config?.propertyPrice && {
                value: config?.propertyPrice!,
            }),
        },
    }, {
        formType: "radio",
        inputConfig: {
            label: "Where are you buying?",
            name: "where_are_you_buying",
            info: "Stamp duty rates can vary depending on where you're buying. Our stamp duty calculator takes that into account.",
            ...(config.buyingLocation && {
                value: config.buyingLocation!,
            }),
            options: [{
                label: "England or Northern Ireland",
                value: "england",
                grid: {
                    sm: 12,
                }
            }, {
                label: "Scotland",
                value: "scotland",
                grid: {
                    sm: 12,
                    md: 6,
                }
            }, {
                label: "Wales",
                value: "wales",
                grid: {
                    sm: 12,
                    md: 6,
                }
            }],
        }
    }, {
        formType: "radio",
        inputConfig: {
            name: "first_time_buyer",
            label: "Are you a first time buyer?",
            ...(config.firstTimeBuyer !== undefined && {
                value: config.firstTimeBuyer ? 'Yes' : 'No',
            }),
            options: [{
                label: "Yes",
                value: "Yes",
                grid: {
                    sm: 12,
                    md: 6,
                }
            }, {
                label: "No",
                value: "No",
                grid: {
                    sm: 12,
                    md: 6,
                }
            }]
        }
    }, {
        formType: "radio",
        disable: "values?.first_time_buyer === 'Yes'",
        inputConfig: {
            name: "only_property",
            label: "Will this be your only property?",
            ...(config?.onlyProperty !== undefined && {
                value: config?.onlyProperty ? 'Yes' : 'No',
            }), 
            options: [{
                label: "Yes",
                value: "Yes",
                grid: {
                    sm: 12,
                    md: 6,
                }
            }, {
                label: "No",
                value: "No",
                grid: {
                    sm: 12,
                    md: 6,
                }
            }]
        }
    }] as any;


    const [stampDutyPayment, setStampDutyPayment] = useState(0);
    const [isInitialValues, setIsInitialValues] = useState(true);

    const calculateDuty = (price: number, bracket: any) => {
        let duty = 0, prevLevel = 0;
        for (const rate of bracket) {
            if (rate.level <= price) {
                duty += (rate.level - prevLevel) * (rate.tax / 100);
                prevLevel = rate.level;
            } else {
                return Math.round(duty + (price - prevLevel) * (rate.tax / 100));
            }
        }
    };

    const rate = (level: number, tax: number) => ({ level, tax });

    const calculateStampDutyScotland = (propertyPrice: number, firstTimeBuyer: boolean, onlyProperty: boolean) => {
        if (firstTimeBuyer) {
            return calculateDuty(propertyPrice, [
                rate(175000, 0),
                rate(250000, 2),
                rate(325000, 5),
                rate(750000, 10),
                rate(Infinity, 12),
            ])!;
        } else {
            //additional property
            if (!onlyProperty) {
                return calculateDuty(propertyPrice, [
                    rate(145000, 6),
                    rate(250000, 8),
                    rate(325000, 11),
                    rate(750000, 16),
                    rate(Infinity, 18),
                ])!;
            } else {
                return calculateDuty(propertyPrice, [
                    rate(145000, 0),
                    rate(250000, 2),
                    rate(325000, 5),
                    rate(750000, 10),
                    rate(Infinity, 12),
                ])!;
            }
        }
    }

    const calculateStampDutyWales = (propertyPrice: number, firstTimeBuyer: boolean, onlyProperty: boolean) => {
        if (firstTimeBuyer) {
            return calculateDuty(propertyPrice, [
                rate(225000, 0),
                rate(400000, 6),
                rate(750000, 7.5),
                rate(1500000, 10),
                rate(Infinity, 12),
            ])!;
        } else {
            if (!onlyProperty) {
                return calculateDuty(propertyPrice, [
                    rate(180000, 4),
                    rate(250000, 7.5),
                    rate(400000, 9),
                    rate(750000, 11.5),
                    rate(1500000, 14),
                    rate(Infinity, 16),
                ])!;
            } else {
                return calculateDuty(propertyPrice, [
                    rate(225000, 0),
                    rate(400000, 6),
                    rate(750000, 7.5),
                    rate(1500000, 10),
                    rate(Infinity, 12),
                ])!;
            }
        }
    }

    const calculateStampDutyEngland = (propertyPrice: number, firstTimeBuyer: boolean, onlyProperty: boolean) => {
        if (firstTimeBuyer) {
            if (propertyPrice <= 625000) {
                return calculateDuty(propertyPrice, [
                    rate(425000, 0),
                    rate(925000, 5),
                    rate(1500000, 10),
                    rate(Infinity, 12),
                ])!;
            } else {
                return calculateDuty(propertyPrice, [
                    rate(250000, 0),
                    rate(925000, 5),
                    rate(1500000, 10),
                    rate(Infinity, 12),
                ])!;
            }
        } else {
            if (!onlyProperty) {
                return calculateDuty(propertyPrice, [
                    rate(250000, 3),
                    rate(925000, 8),
                    rate(1500000, 13),
                    rate(Infinity, 15),
                ])!;
            } else {
                return calculateDuty(propertyPrice, [
                    rate(250000, 0),
                    rate(925000, 5),
                    rate(1500000, 10),
                    rate(Infinity, 12),
                ])!;
            }
        }
    };

    const handleSubmit = (values: Record<any, any>) => {
        const propertyPrice = parseFloat(values?.property_price);
        const firstTimeBuyer = values?.first_time_buyer === "Yes";
        const onlyProperty = firstTimeBuyer ? true : values?.only_property === "Yes";

        switch (values?.where_are_you_buying) {
            case "scotland":
                setStampDutyPayment(calculateStampDutyScotland(propertyPrice, firstTimeBuyer, onlyProperty));
                setIsInitialValues(false);
                break;
            case "england":
                setStampDutyPayment(calculateStampDutyEngland(propertyPrice, firstTimeBuyer, onlyProperty));
                setIsInitialValues(false);
                break;
            case "wales":
                setStampDutyPayment(calculateStampDutyWales(propertyPrice, firstTimeBuyer, onlyProperty));
                setIsInitialValues(false);
                break;
        }
    };

    const renderAccordion = () => (
        <Box paddingTop="79px" paddingBottom="91px">
            <Typography fontSize={36} fontWeight={600} lineHeight="43.88px" marginLeft="20px" color="#0D2A25" fontFamily="'Montserrat', sans-serif" letterSpacing="-0.01em">
                What is stamp duty
            </Typography>
            <Box marginTop="33px">
                <Accordion
                    title="What is stamp duty?"
                    content={<WhatIsStampDuty />}
                />
                <Accordion
                    title="How and when to pay stamp duty?"
                    content={<WhenToPayStampDuty />}
                />
                <Accordion
                    title="Who does not need to pay stamp duty?"
                    content={<WhoDontPayStampDuty />}
                />
                <Accordion
                    title="How to use our stamp duty calculator?"
                    content={<HowToUseStampDuty />}
                />
            </Box>
        </Box>
    )

    const renderCalculator = () => (
        <Calculator
            config={config}
            height="630px"
            title="Stamp duty calculator"
            description="The amount of Stamp Duty you pay depends on the price of the property, where it is and when you complete on the purchase. You’ll pay less as a First Time Buyer."
            headings={[{
                heading: "Stamp duty to pay",
                total: stampDutyPayment,
                subTexts: [],
                isInitialValues,
            }]}
            tabs={[]}
            forms={[
                formFields,
            ]}
            disclaimers={[
                "Your home may be repossessed if you do not keep up repayments on your mortgage.",
                "Our stamp duty calculation is for guidance only and designed to provide an illustrative example."
            ]}
            validations={[
                validateStampDuty,
            ]}
            handleSubmit={handleSubmit}
        />
    );

    return (
        <>
            {(!isCalculatorOnly && !header) && <Header />}
            {(!isCalculatorOnly && !header) && <Banner
                bannerTitle="Stamp Duty Calculator"
                bannerSubtitle="Calculate how much stamp duty you pay for a residential UK property in 2022."

            />}
            <Box maxWidth="1014px" margin="auto" padding={(!isCalculatorOnly && !header) ? "20px" : "20px"}>
                {renderCalculator()}
                {(!isCalculatorOnly && !faq) && renderAccordion()}
            </Box>
            {(!isCalculatorOnly && !footer) && <Footer />}
        </>
    );
}

export default StampDuty;
