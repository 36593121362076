import { Typography } from '@mui/material';

const HowMuchCanIBorrowWithNoDeposit = () => (
    <>
        <Typography component="p" fontWeight={400} fontSize={16} lineHeight="21.79px">
            It is possible to get a mortgage with no deposit.
        </Typography>
        <Typography marginTop="20px" fontWeight={400} fontSize={16} lineHeight="21.79px">
            However, most lenders require a deposit of at least 5% of the purchase price.
        </Typography>
        <Typography marginTop="20px" fontWeight={400} fontSize={16} lineHeight="21.79px">
            100% mortgages are usually linked to a relative’s or friend’s savings account.
        </Typography>
        <Typography marginTop="20px" fontWeight={400} fontSize={16} lineHeight="21.79px">
            Lenders who offer 100% mortgages include:
        </Typography>
        <ul>
            <li>
                <Typography fontWeight={400} fontSize={16} lineHeight="21.79px">
                    Barclays
                </Typography>
            </li>
            <li>
                <Typography fontWeight={400} fontSize={16} lineHeight="21.79px">
                    Lloyds
                </Typography>
            </li>
            <li>
                <Typography fontWeight={400} fontSize={16} lineHeight="21.79px">
                    Tipton & Coseley Building Society
                </Typography>
            </li>
            <li>
                <Typography fontWeight={400} fontSize={16} lineHeight="21.79px">
                    Skipton Building Society
                </Typography>
            </li>
        </ul>
        <Typography marginTop="20px" fontWeight={400} fontSize={16} lineHeight="21.79px">
            With Lloyd’s Lend a Hand Mortgage, instead of putting down a deposit, a family member puts 10% of the purchase price into a 3-year fixed-term savings account.
        </Typography>
        <Typography marginTop="20px" fontWeight={400} fontSize={16} lineHeight="21.79px">
            At the end of the 3 years, your family member will get their savings back with interest if you made all your payments.
        </Typography>
        <Typography marginTop="20px" fontWeight={400} fontSize={16} lineHeight="21.79px">
            The home is still yours. Your family member has no legal rights to it.
        </Typography>
    </>
);

export default HowMuchCanIBorrowWithNoDeposit