import axios from "axios";
import { v4 } from "uuid";
import ReactGA4 from "react-ga4";
import ReactGA, { EventArgs } from "react-ga";

const sessionId = v4();

const {
    REACT_APP_SHOP_URL,
} = process.env;

export const transliterate = (word: string) => {
    let answer = "",
    a: any = {};

    a["Ё"] = "YO";
    a["Й"] = "I";
    a["Ц"] = "TS";
    a["У"] = "U";
    a["К"] = "K";
    a["Е"] = "E";
    a["Н"] = "N";
    a["Г"] = "G";
    a["Ш"] = "SH";
    a["Щ"] = "SCH";
    a["З"] = "Z";
    a["Х"] = "H";
    a["Ъ"] = "'";
    a["ё"] = "yo";
    a["й"] = "i";
    a["ц"] = "ts";
    a["у"] = "u";
    a["к"] = "k";
    a["е"] = "e";
    a["н"] = "n";
    a["г"] = "g";
    a["ш"] = "sh";
    a["щ"] = "sch";
    a["з"] = "z";
    a["х"] = "h";
    a["ъ"] = "'";
    a["Ф"] = "F";
    a["Ы"] = "I";
    a["В"] = "V";
    a["А"] = "a";
    a["П"] = "P";
    a["Р"] = "R";
    a["О"] = "O";
    a["Л"] = "L";
    a["Д"] = "D";
    a["Ж"] = "ZH";
    a["Э"] = "E";
    a["ф"] = "f";
    a["ы"] = "i";
    a["в"] = "v";
    a["а"] = "a";
    a["п"] = "p";
    a["р"] = "r";
    a["о"] = "o";
    a["л"] = "l";
    a["д"] = "d";
    a["ж"] = "zh";
    a["э"] = "e";
    a["Я"] = "Ya";
    a["Ч"] = "CH";
    a["С"] = "S";
    a["М"] = "M";
    a["И"] = "I";
    a["Т"] = "T";
    a["Ь"] = "'";
    a["Б"] = "B";
    a["Ю"] = "YU";
    a["я"] = "ya";
    a["ч"] = "ch";
    a["с"] = "s";
    a["м"] = "m";
    a["и"] = "i";
    a["т"] = "t";
    a["ь"] = "'";
    a["б"] = "b";
    a["ю"] = "yu";

    const characters = word.split("");
    for (const i in characters) {
        if (word.hasOwnProperty(i)) {
            if (a[word[i]] === undefined) {
                answer += word[i];
            } else {
                answer += a[word[i]]
            }
        }
    }

    return answer;
};

export const hexToRgb = (hex: string) => {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
        ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
        }
        : null;
};

export const numberWithCommas = (x: string) => {
    return x.toString().replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const trackEvent = async (apiKey: string, data: any) => {
    await axios.post(`${REACT_APP_SHOP_URL}/api/v1/shop/client/analytics`, {
        sessionId,
        apiKey,
        ...data,
    })
};

export const isUniversalAnalytics = (googleAnalyticsTrackingId: string) => {
    return googleAnalyticsTrackingId.startsWith("UA-");
  };
  
  export const isGA4Analytics = (googleAnalyticsTrackingId: string) => {
    return googleAnalyticsTrackingId.startsWith("G-");
  };
  
  export const initializeUniversalAnalytics = (
    googleAnalyticsTrackingId: string
  ) => {
    ReactGA.initialize(googleAnalyticsTrackingId);
    ReactGA.pageview(window.location.pathname + window.location.search);
  };
  
  export const initializeGA4Analytics = (
    googleAnalyticsTrackingId: string,
  ) => {
    let gaOptions = {};
  
    if (sessionId) {
      gaOptions = {
        cookieDomain: "auto",
        clientId: sessionId,
        cookieFlags: 'samesite=none;secure'
      };
    }
  
    ReactGA4.initialize(googleAnalyticsTrackingId, {
      gaOptions,
    });
    ReactGA4.send("pageview");
  };
  
  export const initializeGoogleAnalytics = (
    googleAnalyticsTrackingId: string[],
  ) => {
    const uaIndex = googleAnalyticsTrackingId.findIndex((id) =>
      isUniversalAnalytics(id)
    );
    const ga4Index = googleAnalyticsTrackingId.findIndex((id) =>
      isGA4Analytics(id)
    );
    if (uaIndex !== -1) {
      initializeUniversalAnalytics(googleAnalyticsTrackingId[uaIndex]);
    }
  
    if (ga4Index !== -1) {
      initializeGA4Analytics(googleAnalyticsTrackingId[ga4Index]);
    }
  };
  
  export const trackGAEvent = (
    googleAnalyticsTrackingId: string[],
    event: EventArgs
  ) => {
    if (Array.isArray(googleAnalyticsTrackingId)) {
      const uaIndex = googleAnalyticsTrackingId.findIndex((id) =>
        isUniversalAnalytics(id)
      );
      const ga4Index = googleAnalyticsTrackingId.findIndex((id) =>
        isGA4Analytics(id)
      );
  
      if (uaIndex !== -1) {
        ReactGA.event({
          ...event,
          nonInteraction: true,
        });
      }
  
      if (ga4Index !== -1) {
        ReactGA4.event({
          ...event,
          nonInteraction: true,
        });
      }
    }
  };