import { createTheme } from "@mui/material/styles";

import { ThemeData } from ".";
import AvenirBold from "./assets/fonts/Avenir/Avenir-Bold.ttf";
import AvenirRegular from "./assets/fonts/Avenir/Avenir-Regular.ttf";
import FuturaBold from "./assets/fonts/Futura/Futura-Bold-font.otf";
import FuturaBook from "./assets/fonts/Futura/Futura-Book-font.otf";
import FuturaBlack from "./assets/fonts/Futura/Futura-Extra-Black-font.otf";
import FuturaHeavy from "./assets/fonts/Futura/Futura-Heavy-font.otf";
import FuturaLight from "./assets/fonts/Futura/Futura-Light-font.otf";
import FuturaMedium from "./assets/fonts/Futura/Futura-Medium-font.otf";
import InterstateBold from "./assets/fonts/interstate/Interstate-Bold.otf";
import InterstateExtraLight from "./assets/fonts/interstate/Interstate-ExtraLight.otf";
import InterstateLight from "./assets/fonts/interstate/Interstate-Light.otf";
import InterstateRegular from "./assets/fonts/interstate/Interstate-Regular.ttf";
import RajdhaniBold from "./assets/fonts/Radjhani/Rajdhani-Bold.ttf";
import RajdhaniLight from "./assets/fonts/Radjhani/Rajdhani-Light.ttf";
import RajdhaniMedium from "./assets/fonts/Radjhani/Rajdhani-Medium.ttf";
import RajdhaniRegular from "./assets/fonts/Radjhani/Rajdhani-Regular.ttf";
import RajdhaniSemiBold from "./assets/fonts/Radjhani/Rajdhani-SemiBold.ttf";
import UrbaneDemiBold from "./assets/fonts/UrbaneDemi/Urbane-Bold.ttf";
import UrbaneDemiSemiBold from "./assets/fonts/UrbaneDemi/Urbane-DemiBold.ttf";
import UrbaneDemiLight from "./assets/fonts/UrbaneDemi/Urbane-Light.ttf";
import UrbaneDemiMedium from "./assets/fonts/UrbaneDemi/Urbane-Medium.ttf";

const mainTheme = createTheme({
    palette: {
        primary: {
            main: "#ff385c",
        },
        secondary: {
            main: "#5F5F5F",
        },
    },
    typography: {
        h1: {
            fontSize: 30,
        },
        h2: {
            fontSize: 20,
        },
        h3: {
            fontSize: 18,
        },
        h4: {
            fontSize: 16,
        },
        h5: {
            fontSize: 14,
        },
        h6: {
            fontSize: 12,
        },
        body1: {
            lineHeight: 1.1,
        },
        body2: {
            fontSize: 12,
        },
    },
});

export const initializeTheme = (theme: ThemeData) => {
    return createTheme(
        {
            ...mainTheme,
            palette: {
                ...mainTheme.palette,
                primary: {
                    ...mainTheme?.palette?.primary,
                    //@ts-ignore
                    main: theme?.customization?.primaryColor,
                },
                secondary: {
                    ...mainTheme?.palette?.primary,
                    //@ts-ignore
                    main: theme?.customization?.secondaryColor,
                },
            },
            //@ts-ignore
            custom: theme!,
            ...(theme?.customization?.fontFamily && {
                typography: {
                    fontFamily: `${theme?.customization?.fontFamily} !important`,
                },
            }),
        },
        {
            components: {
                MuiSlider: {
                    styleOverrides: {
                        root: {
                            marginTop: "40px !important",
                        },
                        markLabel: {
                            strong: {
                                color: "#9E9E9E",
                                fontSize: 14,
                                fontWeight: 600,
                            },
                            '&[data-index="0"]': {
                                transform: "translateX(0%)",
                            },
                            '&[data-index="1"]': {
                                transform: "translateX(-100%)",
                            },
                        },
                    },
                },
                MuiLinearProgress: {
                    styleOverrides: {
                        root: {
                            height: 10,
                            backgroundColor: "#DEDEDE",
                        },
                        bar: {
                            borderRadius: 20,
                        },
                    },
                },
                MuiLink: {
                    styleOverrides: {
                        root: {
                            letterSpacing: 1.5,
                            marginBottom: 10,
                        },
                    },
                },
                MuiInputBase: {
                    styleOverrides: {
                        input: {
                            "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                                {
                                    WebkitAppearance: "none",
                                    margin: 0,
                                },
                        },
                    },
                },
                MuiCssBaseline: {
                    styleOverrides: `
                    @font-face {
                        font-family: 'Interstate';
                        font-style: normal;
                        font-display: swap;
                        font-weight: 400;
                        src: local('Interstate'), url(${InterstateRegular}) format('truetype');
                    }
                    
                    @font-face {
                        font-family: 'Interstate';
                        font-style: normal;
                        font-display: swap;
                        font-weight: 300;
                        src: local('Interstate'), url(${InterstateLight}) format('opentype');
                    }
                    
                    @font-face {
                        font-family: 'Interstate';
                        font-style: normal;
                        font-display: swap;
                        font-weight: 100;
                        src: local('Interstate'), url(${InterstateExtraLight}) format('opentype');
                    }
                    
                    @font-face {
                        font-family: 'Interstate';
                        font-style: normal;
                        font-display: swap;
                        font-weight: 700;
                        src: local('Interstate'), url(${InterstateBold}) format('opentype');
                    }

                    @font-face {
                        font-family: 'Rajdhani';
                        font-style: normal;
                        font-display: swap;
                        font-weight: 400;
                        src: local('Rajdhani'), url(${RajdhaniRegular}) format('truetype');
                    }
                    
                    @font-face {
                        font-family: 'Rajdhani';
                        font-style: normal;
                        font-display: swap;
                        font-weight: 300;
                        src: local('Rajdhani'), url(${RajdhaniLight}) format('truetype');
                    }

                    @font-face {
                        font-family: 'Rajdhani';
                        font-style: normal;
                        font-display: swap;
                        font-weight: 500;
                        src: local('Rajdhani'), url(${RajdhaniMedium}) format('truetype');
                    }

                    @font-face {
                        font-family: 'Rajdhani';
                        font-style: normal;
                        font-display: swap;
                        font-weight: 600;
                        src: local('Rajdhani'), url(${RajdhaniSemiBold}) format('truetype');
                    }

                    @font-face {
                        font-family: 'Rajdhani';
                        font-style: normal;
                        font-display: swap;
                        font-weight: 700;
                        src: local('Rajdhani'), url(${RajdhaniBold}) format('truetype');
                    }
                    
                    @font-face {
                        font-family: 'UrbaneDemi';
                        font-style: normal;
                        font-display: swap;
                        font-weight: 300;
                        src: local('UrbaneDemi'), url(${UrbaneDemiLight}) format('truetype');
                    }

                    @font-face {
                        font-family: 'UrbaneDemi';
                        font-style: normal;
                        font-display: swap;
                        font-weight: 500;
                        src: local('UrbaneDemi'), url(${UrbaneDemiMedium}) format('truetype');
                    }

                    @font-face {
                        font-family: 'UrbaneDemi';
                        font-style: normal;
                        font-display: swap;
                        font-weight: 600;
                        src: local('UrbaneDemi'), url(${UrbaneDemiSemiBold}) format('truetype');
                    }

                    @font-face {
                        font-family: 'UrbaneDemi';
                        font-style: normal;
                        font-display: swap;
                        font-weight: 700;
                        src: local('UrbaneDemi'), url(${UrbaneDemiBold}) format('truetype');
                    }

                    @font-face {
                        font-family: 'Avenir';
                        font-style: normal;
                        font-display: swap;
                        font-weight: 400;
                        src: local('Avenir'), url(${AvenirRegular}) format('truetype');
                    }
                    
                    @font-face {
                        font-family: 'Avenir';
                        font-style: normal;
                        font-display: swap;
                        font-weight: 700;
                        src: local('Avenir'), url(${AvenirBold}) format('truetype');
                    }
                    
                    @font-face {
                        font-family: 'Futura';
                        font-style: normal;
                        font-display: swap;
                        font-weight: 300;
                        src: local('Futura'), url(${FuturaLight}) format('opentype');
                    }
                    
                    @font-face {
                        font-family: 'Futura';
                        font-style: normal;
                        font-display: swap;
                        font-weight: 400;
                        src: local('Futura'), url(${FuturaBook}) format('opentype');
                    }
                    
                    @font-face {
                        font-family: 'Futura';
                        font-style: normal;
                        font-display: swap;
                        font-weight: 500;
                        src: local('Futura'), url(${FuturaMedium}) format('opentype');
                    }
                    
                    @font-face {
                        font-family: 'Futura';
                        font-style: normal;
                        font-display: swap;
                        font-weight: 700;
                        src: local('Futura'), url(${FuturaBold}) format('opentype');
                    }
                    
                    @font-face {
                        font-family: 'Futura';
                        font-style: normal;
                        font-display: swap;
                        font-weight: 800;
                        src: local('Futura'), url(${FuturaHeavy}) format('opentype');
                    }
                    
                    @font-face {
                        font-family: 'Futura';
                        font-style: normal;
                        font-display: swap;
                        font-weight: 900;
                        src: local('Futura'), url(${FuturaBlack}) format('opentype');
                    }

                `,
                },
            },
        }
    );
};
