import { Typography } from '@mui/material';

const HowToUseStampDuty = () => (
    <>
        <Typography component="p" fontWeight={400} fontSize={16} lineHeight="21.79px">
            To use our stamp duty calculator you need to provide these details:
        </Typography>
        <ul>
            <li>
                <Typography fontWeight={400} fontSize={16} lineHeight="21.79px">
                    Where you're buying (England & Northern Ireland, Wales or Scotland)
                </Typography>
            </li>
            <li>
                <Typography fontWeight={400} fontSize={16} lineHeight="21.79px">
                    If it's your first home, next home, and if you'll own multiple properties
                </Typography>
            </li>
            <li>
                <Typography fontWeight={400} fontSize={16} lineHeight="21.79px">
                    The value of the property you're buying
                </Typography>
            </li>
        </ul>
        <Typography marginTop="20px" fontWeight={400} fontSize={16} lineHeight="21.79px">
            Our stamp duty calculator will then calculate the total Stamp Duty Land Tax, Land and Buildings Transaction Tax or Land Transaction Tax that you'll need to pay.
        </Typography>
        <Typography marginTop="20px" fontWeight={400} fontSize={16} lineHeight="21.79px">
            Your mortgage adviser can give you general information on the rates you need to pay. Mortgage advisers are not qualified to give tax advice.
        </Typography>

        <Typography marginTop="20px" fontWeight={400} fontSize={16} lineHeight="21.79px">
            If you need advice on tax, then speak to an independent tax and legal specialist.
        </Typography>
    </>
);

export default HowToUseStampDuty;