import { Typography } from '@mui/material';

const ShouldIBorrowMaximumAmount = () => (
    <>
        <Typography component="p" fontWeight={400} fontSize={16} lineHeight="21.79px">
            When a lender offers you a mortgage, they’ve decided how much they’ll lend you based on:
        </Typography>
        <ul>
            <li>
                <Typography fontWeight={400} fontSize={16} lineHeight="21.79px">
                    your salary
                </Typography>
            </li>
            <li>
                <Typography fontWeight={400} fontSize={16} lineHeight="21.79px">
                    secondary sources of income, such as investments
                </Typography>
            </li>
            <li>
                <Typography fontWeight={400} fontSize={16} lineHeight="21.79px">
                    how much you can afford to pay
                </Typography>
            </li>
        </ul>
        <Typography marginTop="20px" fontWeight={400} fontSize={16} lineHeight="21.79px">
            To decide how much you can afford to pay, a lender must consider a range of risk-based factors, such as a rise in interest rates or potential loss of employment.
        </Typography>
        <Typography marginTop="20px" fontWeight={400} fontSize={16} lineHeight="21.79px">
            Even if a lender thinks you can afford the full amount they’re offering, you should decide how much is right for you.
        </Typography>
        <Typography marginTop="20px" fontWeight={400} fontSize={16} lineHeight="21.79px">
            Some people do borrow as much as they can in order to get their dream property. Others, on the other hand, may prefer to borrow less and take on less risk. This is ultimately down to personal preferences and attitudes to risk.
        </Typography>
        <Typography marginTop="20px" fontWeight={400} fontSize={16} lineHeight="21.79px">
            Think about what’s best for you. Remember, you might lose your home if you do not keep paying your mortgage.
        </Typography>
    </>
);

export default ShouldIBorrowMaximumAmount;