import { Typography } from '@mui/material';

const WhoDontPayStampDuty = () => (
    <>
        <Typography fontWeight={400} fontSize={16} lineHeight="21.79px">
            You do not need to pay stamp duty land tax if you're:
        </Typography>
        <ul>
            <li>
                <Typography fontWeight={400} fontSize={16} lineHeight="21.79px">
                    Buying a property valued below the stamp duty threshold
                </Typography>
            </li>
            <li>
                <Typography fontWeight={400} fontSize={16} lineHeight="21.79px">
                    Transferring the deeds of your home to someone
                </Typography>
            </li>
            <li>
                <Typography fontWeight={400} fontSize={16} lineHeight="21.79px">
                    A first time buyer in England or Northern Ireland if you're buying a property that costs less than £425,000
                </Typography>
            </li>
        </ul>
    </>
);

export default WhoDontPayStampDuty;