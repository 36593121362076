import { Toolbar, useTheme } from "@mui/material";


const Header = () => {
    const theme = useTheme();

    return (
        <Toolbar>

            <img
                //@ts-ignore
                src={theme?.custom?.logo} alt="logo" style={{ margin: "auto", maxWidth: "240px", maxHeight: "90px" }} />
        </Toolbar>
    )
};

export default Header;