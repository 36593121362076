import { Button, useTheme } from "@mui/material";
import { useEffect } from "react";
import { Form } from "react-final-form";

import Input, { IInput } from "../input";
import Radio, { IRadioField } from "../radio";

import "./index.scss";
import { AppConfig } from "../../App";

export interface FormConfig {
  formType: string;
  disable?: string;
  inputConfig?: IInput | IRadioField;
}

interface IFormWizard {
  config: FormConfig[];
  btnLabel?: string;
  handleSubmit: (values: Record<any, any>) => void;
  validator: (values: Record<any, any>) => any;
  appConfig?: AppConfig;
}

const FormWizard: React.FC<IFormWizard> = ({
  appConfig,
  btnLabel = "Calculate",
  handleSubmit,
  validator,
  config,
}) => {
  const theme = useTheme();

  const renderForm = (
    configItem: FormConfig,
    index: number,
    disable: boolean = false
  ) => {
    if (disable) return null;

    switch (configItem?.formType) {
      case "input":
        return <Input key={index} {...(configItem?.inputConfig! as IInput)} />;
      case "radio":
        return (
          <Radio key={index} {...(configItem?.inputConfig! as IRadioField)} />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    const autoCalculate = appConfig?.autoCalculate;

    if (autoCalculate) {
      const button = document.getElementById("calculator-submit-btn");

      if (button) {
        button.click();
      }
    }
  }, []);

  return (
    <Form
      initialValues={{}}
      validate={validator}
      onSubmit={handleSubmit}
      render={({ handleSubmit, values, submitting, pristine, valid }) => (
        <form
          onSubmit={handleSubmit}
          style={{ width: "100%", display: "flex", flexDirection: "column" }}
        >
          {config.map((configItem: FormConfig, index: number) =>
            renderForm(configItem, index, eval(configItem.disable! || ""))
          )}

          <Button
            id="calculator-submit-btn"
            type="submit"
            className="form-btn"
            disableElevation
            variant="contained"
            sx={{
              backgroundColor:
                theme?.custom?.apiKey ===
                  "3e1e4b3b-923f-4aad-91b3-3a0d562e05ee" ||
                theme?.custom?.apiKey === "f74e7819-6147-400f-9910-3aed25aef243"
                  ? theme?.palette?.secondary?.main
                  : "transparent",
              border: `2px solid ${theme?.palette?.secondary?.main}`,
              color:
                theme?.custom?.apiKey ===
                  "3e1e4b3b-923f-4aad-91b3-3a0d562e05ee" ||
                theme?.custom?.apiKey === "f74e7819-6147-400f-9910-3aed25aef243"
                  ? "#FFF"
                  : theme?.palette?.secondary?.main,
              fontWeight: 700,
              fontSize: 16,
              "&:hover": {
                background: theme?.palette?.secondary?.main,
                color: "#FFF",
              },
            }}
          >
            {btnLabel}
          </Button>
        </form>
      )}
    />
  );
};

export default FormWizard;
