import { Button, Typography, useTheme } from "@mui/material";

import "./index.scss";

const NextSteps = () => {
    const theme = useTheme();

    return (
        <div className="calculator-results-next-steps">
            {theme?.custom?.apiKey === "acddfcb8-d195-42cd-bfd2-9506aecabc89" ||
            theme?.custom?.apiKey === "3e2b1096-3698-4a1a-8605-f7f2df047634" ||
            theme?.custom?.apiKey === "3e2b1096-3698-4a1a-8605-f7f2df047635" ? (
                <>
                    {theme?.custom?.apiKey === "acddfcb8-d195-42cd-bfd2-9506aecabc89" ? (
                        <Typography className="calculator-results-next-steps_text">
                            The next step is speaking with your adviser who can
                            guide you through the process and find the best
                            mortgage deal for you. Please either leave your
                            details here or if you don’t have time now, book a
                            meeting and your adviser will run through these
                            details with you at a convenient time. Select your
                            preference below.
                        </Typography>
                    ) : null}
                    {theme?.custom?.apiKey === "3e2b1096-3698-4a1a-8605-f7f2df047634" ||
                    theme?.custom?.apiKey === "3e2b1096-3698-4a1a-8605-f7f2df047635" ? (
                        <Typography className="calculator-results-next-steps_text">
                            If you are ready to apply or would like to discuss
                            your options further with an Arc & Co. Mortgages
                            advisor, please visit{" "}
                            <a href="https://arcandco-mortgages.com/the-team">
                                our contact page
                            </a>{" "}
                            to get in touch. We can provide you with the right
                            advice and get things moving.
                        </Typography>
                    ) : null}
                </>
            ) : (
                <>
                    <Typography className="calculator-results-next-steps_heading">
                        What’s the Next Step?
                    </Typography>
                    <Typography className="calculator-results-next-steps_text">
                        If you're ready to get a mortgage, the next step is to
                        answer a few more questions. Then a Mortgage adviser
                        will find the best mortgage deal for you.
                    </Typography>
                </>
            )}
            <div className="calculator-results-next-steps_button">
                {theme?.custom?.links?.getStartedURL! && (
                    <Button
                        href={theme?.custom?.links?.getStartedURL!}
                        target="_blank"
                        className="btn"
                        disableElevation
                        variant="contained"
                        sx={{
                            backgroundColor: theme?.palette?.secondary?.main,
                            textAlign: "center",
                            "&:hover": {
                                backgroundColor: "transparent",
                                border: `1px solid ${theme?.palette?.secondary?.main}`,
                                color: theme?.palette?.secondary?.main,
                            },
                        }}
                    >
                        Get started
                    </Button>
                )}
                {theme?.custom?.links?.compareDealsURL! && (
                    <Button
                        href={theme?.custom?.links?.compareDealsURL!}
                        className="btn"
                        disableElevation
                        variant="contained"
                        sx={{
                            textAlign: "center",
                            backgroundColor: "#FFF",
                            border: `2px solid ${theme?.palette?.secondary?.main}`,
                            color: theme?.palette?.secondary?.main,
                            "&:hover": {
                                background: theme?.palette?.secondary?.main,
                                color: "#FFF",
                            },
                        }}
                    >
                        {theme?.custom?.apiKey === "acddfcb8-d195-42cd-bfd2-9506aecabc89" ||
                        theme?.custom?.apiKey === "49e875cc-1fc7-4b89-958a-5ce824323bf9"
                            ? "Book Meeting"
                            : "Compare Deals"}
                    </Button>
                )}
            </div>
        </div>
    );
};

export default NextSteps;
